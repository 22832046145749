import React, { Component } from 'react'; 
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button } from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles'; 

const useStyles = theme => ({
  buttonAdd:{
    textTransform:'capitalize',
  },
});

class ExportExcelButton extends Component {
   
	constructor(props){
		super(props)
		this.state = { 
    }
    this.fileDownload = this.fileDownload.bind(this);
	}

    componentDidMount() { 
    }

    componentWillUnmount() { 
    }

  fileDownload = () => {
    this.props.clickProp(true)
  }

    render() { 
      const { classes } = this.props; 
      return (
        <React.Fragment> 
            <Button onClick={(e) => this.fileDownload(e)} style={{marginLeft:"5px"}} color="inherit" className={classes.buttonAdd} startIcon={<GetAppIcon/>} color="secondary" variant="contained">Export to Excel</Button>
        </React.Fragment>
      );
    }
  }
  
  
 export default (withStyles)(useStyles)(ExportExcelButton);;