import React, { Component } from "react";
import axios from 'axios'; 
import XMLParser from 'react-xml-parser';


class Whatsapp extends Component{
    constructor(props){
        super(props);
        this.state = {
            responseResult: [],
        }
        this.sendWhatsAppMassege = this.sendWhatsAppMassege.bind(this);
    }

    sendWhatsAppMassege = () => {
        const whatsAppBaseUrl =  'https://nz8982.api.infobip.com/omni/1/scenarios';
        const headers = {
            "Authorization": "Basic bmF2bmVldDpOYXZuZWV0QDk5",
            "Content-Type": "application/json" 
        }
        const body = 
            {
                "name": "My WHATSAPP-SMS scenario",
                "flow": [
                    {
                        "from": "WhatsAppSender",
                        "channel": "WHATSAPP"
                    },
                    {
                        "from": "InfoSMS",
                        "channel": "SMS"
                    }
                ],
                "default": true
            }	
        
        axios.get(whatsAppBaseUrl, body, {headers: headers})
        .then(response => {
            const xml = new XMLParser().parseFromString(response.data); 
               // console.log(xml)
           this.setState({responseResult: xml})
           //console.log(response)
        })
        .catch(error => {
            console.log(error)
        })
    }

    render(){
        return(
            <React.Fragment>
                <p onClick={(e) => this.sendWhatsAppMassege(e)}>Hello whatsapp</p>
                {this.state.response}
            </React.Fragment>
        );
    }

}

export default Whatsapp;