import React, { Component } from 'react';
import { apiURL } from '../utils';  
import Footer from '../include/Footer';
import Header from '../include/Header';
import { withStyles } from '@material-ui/core/styles'; 
import { CssBaseline, Grid, Container, Button, TextField,Table, TableBody, TableCell, TableHead, TableRow, Snackbar, Switch, InputAdornment } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';   
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import Delete from "../delete/Delete";


import CommentIcon from '@material-ui/icons/Comment';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';   
import Box from '@material-ui/core/Box';
//import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import "../style/Style.css";  
//import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import Meta from "../meta/Meta";
import XLSX from 'xlsx';
import ExportExcelButton from '../utils/ExportExcelButton';


const drawerWidth = 280;
// const useStyles = makeStyles => ({
    
// });

const useStyles = theme => ({
    buttonEdit: {
      textTransform:'capitalize',
      border:'1px solid #035eab',
      borderRadius:'0px',
      marginRight:'10px',
      padding:'2px 5px',
    },
    buttonAdd:{
      textTransform:'capitalize',
    },
    table:{
        minWidth: 650,
    },
   
   
  });

 class Forum extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
           // drawerOpen: false,
            drawerOpen: true,
            forumData: [],
            page: 1,
            per:20,
            total_pages: null,
            loading: false,
            showSnackbar: false,
            snackBarMessage: '',
            severityColor: '',
            searchValue:'',
            changeCategory: '',
            changeTag:'',
            noData: '',
        }
        this.handleDrawerOpen       = this.handleDrawerOpen.bind(this);
    }

    handleDrawerOpen(drawerState){
        this.setState({
          drawerOpen: drawerState,
        })
    }


    componentDidMount() { 
        this._isMounted = true; 
        this.teacherListData(); 
        this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);
        });
        
    }

    componentWillUnmount() {
        this._isMounted = false; 
    }
  

    teacherListData = () => {
        //this.setState({ loading: true }); 
        const { per, page, teacherData } = this.state; 
        const headers = { 
            'Content-type': 'application/json'
        }
        const body = {
            "search": document.getElementById('search').value,
            "category_id": this.state.changeCategory,
            "tag_id": this.state.changeTag,
            "user_status": '',
            "latest":"",
            "most_liked": ""
        }
        axios.post(apiURL('forum?per_page='+per+'&page='+page), body, {headers: headers})
        .then(response => {
            
        if(this._isMounted){
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({  
                    forumData: (resultData.total < 20)?resultData.result:[...this.state.forumData, ...resultData.result],
                    scrolling: false,
                    total_pages: resultData.total_pages,
                });
                this.setState({ loading: false, scrolling: true });
            }
            else{
                this.setState({ loading: false, scrolling: false, noData: "No Forum Found"}); 
            }
        }
        })
        .catch(error => {
            console.log(error)
        })
    }

    loadMore = () => { 
        if(this._isMounted){
            this.setState(
            prevState => ({
                page: prevState.page + 1,
                scrolling: true
            }),
            this.speakerListData
            ); 
        }
      };

      handleScroll = () => {   
        if(this._isMounted){ 
            var lastLi = document.querySelector("Table"); 
            if(lastLi !== null){
                var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
                var pageOffset = window.pageYOffset + window.innerHeight;
                if (pageOffset > lastLiOffset) {
                    if(this.state.scrolling){
                        this.loadMore();
                    }
                } 
            }
        }
      };

    snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ showSnackbar: false }); 
    }

    changeStatus = (id, status) => { 
        const headers = { 
            'Content-type': 'application/json'
        }
        const body = {
            "id": id,
            "status":status.toString(),
        }
        axios.post(apiURL('forum/change_status'), body, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                const updateForumData = this.state.forumData.map(forum=>{
                    if(forum.fldi_id === id){
                        const _forum = {...forum};
                        _forum.flg_status = status==="0"?"1":"0";
                        return _forum;
                    }
                    return forum;
                });
                this.setState({ forumData: updateForumData });
            }
        })
        .catch(error => {
            console.log(error)
        })
      }

      searchHandler = (e) => {
        this.setState({page: 1, searchValue:e.target.value})
        this.teacherListData();
    }
    successDelete = (e, id, index) => {  
        if(e === true){
            const deleteData = this.state.forumData;
            deleteData.splice(index, 1);
            this.setState({
                forumData: deleteData, 
                showSnackbar: true, 
                snackBarMessage: "data deleted suceesfully", 
                severityColor: 'success',
                snackBarTimer: 3000
            }); 
        } 
    }

    exportFile() {
        let headers = [["Title", "Category", "Author Name", "Status", "Delete"]]
        this.state.forumData.forEach((value) => {
          let valueArray = [value.fldv_title, value.fldv_category, value.author_name,  (value.flg_status === '1')?"Active":"Deactive", (value.flg_is_deleted === '1')?"Not Delete":"Deleted"]
          headers.push(valueArray)
        })
    
        const wb = XLSX.utils.book_new()
        const wsAll = XLSX.utils.aoa_to_sheet(headers)
        
        XLSX.utils.book_append_sheet(wb, wsAll, "Forum")
            XLSX.writeFile(wb, "export-discussion.xlsx")
      }
   
    render(){
        const { classes } = this.props; 
        const loadingCSS = {
            height: "30px",
            margin: "10px"
          };
          const search = {  
            border: '1px solid',
            float: 'left',
            marginRight: '67px',
            background: 'rgba(255, 255, 255, 0.29)',
        }
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
        return(
            <div>
                <Helmet>
                    <title>Forum</title>
                </Helmet>
                <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)}  />
                <CssBaseline /> 
                <div style={{ paddingTop: '5rem', paddingLeft: (this.state.drawerOpen?drawerWidth+15:72+15), transition: 'padding 0.5s ease'   }}>
                    <main>    
                        <Container maxWidth="lg">
                        <Box  bgcolor="background.paper" mt={2} boxShadow={3}>  
                        <Alert 
                            action={
                                <div> 
                                    <TextField className="textField" type="text" placeholder="...Search"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            style: {color: '#414141',backgroundColor:'rgba(255, 255, 255, 0.29)'}
                                        }} 
                                        size="small"
                                        id="search"
                                        variant="outlined"
                                        style={{fontColor:"#fff", marginRight:'1rem',}} 
                                        name="search"
                                        onChange={e => this.searchHandler(e)} 
                                    /> 
                                    {/*<Button color="inherit" size="small" onClick={this.speakerAddDialog}> Add Speaker </Button> */} <ExportExcelButton clickProp={(e) => this.exportFile(e)}/>
                                </div>
                            } 
                            variant="outlined" severity="default" boxShadow={3}><h2>Forum </h2>
                        </Alert>
                        </Box> 
                            <Grid container spacing={3}  align="center" > 
                                <Grid item xs={12}>  
                                <Box  bgcolor="background.paper" boxShadow={3} px={2} py={2} mt={1}>
                                <TableContainer  mt={1} component={Paper} className="customTable">
                                    <Table size="small" stickyHeader aria-label="sticky table">
                                        <TableHead> 
                                            <TableRow>
                                                <TableCell>Id</TableCell> 
                                                <TableCell> Title</TableCell>
                                                <TableCell>Category</TableCell>
                                                <TableCell> Author Name </TableCell>  
                                                <TableCell>Status</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody> 
                                            {this.state.forumData.map((arrList, index) => {    
                                             return <TableRow key={arrList.fldi_id}>  
                                                    <TableCell>{index+1}</TableCell> 
                                                    <TableCell>{arrList.fldv_title}</TableCell>
                                                    <TableCell>{arrList.fldv_category}</TableCell>
                                                    <TableCell>{arrList.author_name}</TableCell>  
                                                    <TableCell> <Switch size="small" checked={arrList.flg_status==="1"} color="primary" onChange={() => this.changeStatus(arrList.fldi_id, arrList.flg_status)}/></TableCell>
                                                    <TableCell>
                                                        <Link to={'forum/'+arrList.fldi_id}><Button className={classes.buttonEdit} variant="outlined" color="default" startIcon={<CommentIcon style={{color:'#035eab',marginLeft:'5px', fontSize:'0.875rem',}} />}>Comment</Button></Link>
                                                        
                                                        <Delete deleteID={arrList.fldi_id} table={'forum'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>

                                                        <Meta primaryId={arrList.fldi_id} useFor={"inner_page"} table={'forum'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>
                                                    </TableCell>
                                                </TableRow>
                                            })} 
                                        </TableBody>
                                    </Table> 
                                  </TableContainer>
                                  </Box>
                                    <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS} >
                                        <span style={loadingTextCSS}>Loading...</span>
                                        <span>{(this.state.scrolling)?this.state.noData:""}</span>
                                    </div>
                                    
                                </Grid>
                           </Grid> 
                           <Footer />
                        </Container>
                    </main>
                </div> 

                <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
            </div>
        );
    }
 }
  
 export default (withStyles)(useStyles)(Forum);