import React from 'react';
import clsx from 'clsx';
import { AppBar, CssBaseline, Toolbar, Typography, Popover, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, IconButton, Drawer, Divider, List } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert'; 
import { useHistory } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu'; 
import Sidenav from './Sidenav';
import Notification_data from './Notification_data';
import ChangePassword from './ChangePassword';
import logo from '../logo.png';
import { apiURL } from '../utils'; 

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, 
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position:'fixed'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    // marginRight: 36,
    //flex:1
    marginRight:0,
  },
  menuButtonHidden: {
    display: 'none',
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -16,
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: { 
    position: 'fixed', 
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  logoTop:{
    maxWidth:'100%',
    width:160,

  }
})); 

export default function Header(props){ 
   const userData = JSON.parse(localStorage.getItem('userData')); 
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dialogOpn, setOpen] = React.useState(false); 
    const { forwardDrawerOpen } = props;
    //const [sidePanOpen, setSidePanOpen] = React.useState(false);
    const [sidePanOpen, setSidePanOpen] = React.useState(true);
    //const { to, maxWidth } = props;
    
   const [state, setState] = React.useState({ 
      email:"", 
      showSuccess: false,
      showError: false,
      props:''
    });
    const [old_passwordState, set_old_passwordState] = React.useState({
      old_password: "",
      old_password_error: '',
    });
    const [passwordState, set_passwordState] = React.useState({  
      password: "",
      password_error: '', 
    });
    const [confirm_passwordState, set_confirm_passwordState] = React.useState({ 
      confirm_password: "",
      confirm_password_error: '', 
    });
 
    const handleDrawerOpen = () => {
        setSidePanOpen(true); 
        forwardDrawerOpen && forwardDrawerOpen(true);
        //forwardDrawerOpen && forwardDrawerOpen(false);
    };
    const handleDrawerClose = () => {
        setSidePanOpen(false); 
        forwardDrawerOpen && forwardDrawerOpen(false);
        //forwardDrawerOpen && forwardDrawerOpen(true);
    };
    
    const handleDialogOpen = () => {
        setOpen(true);
        handleClose();
    };

    const logout = () => {  
        sessionStorage.clear();
        localStorage.clear();
        history.push("/");
    }

    
    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const old_Password_changeHandler = e => { 
       set_old_passwordState({ 
            old_password: e.target.value,   
        }); 
    }

    const password_changeHandler = e => { 
      set_passwordState({  
          password: e.target.value,  
      }); 
    } 
     const confirm_password_changeHandler = e => { 
      set_confirm_passwordState({  
          confirm_password: e.target.value,  
      }); 
    }

    const submitHandler = e => {
        e.preventDefault();  
        if(!old_passwordState.old_password){ 
          set_old_passwordState({
            old_password_error: "Please enter old password",
          })
          return false;
        } 
        else if(old_passwordState.old_password !== userData.fldv_password)
        {
          set_old_passwordState({
            old_password_error: "old password not match",
            old_password: ""
          })
          return false;
        }
        if(!passwordState.password){ 
          set_passwordState({
            password_error: "Please enter password"
          })
          return false;
        } 
        if(!confirm_passwordState.confirm_password){ 
          set_confirm_passwordState({
            confirm_password_error: "Please enter confirm password"
          })
          return false;
        }
        else if(confirm_passwordState.confirm_password !== passwordState.password)
        {
          set_confirm_passwordState({
            confirm_password_error: "password and confirm password shold be same",
            confirm_password: ""
          })
          return false;
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({'email':userData.fldv_email, 'password':passwordState.password })
        };
        fetch(apiURL('login/change_password'), requestOptions)
            .then(async response => {
                const data = await response.json(); 
                if (!data.status) { 
                   // return this.setState({error: true});
                }  
                localStorage.setItem('userData', JSON.stringify(data.result));
                setState({ showSuccess: true});
                set_old_passwordState({ old_password: ""});
                set_passwordState({ password: ""});
                set_confirm_passwordState({ confirm_password: ""});
                handleDialogClose();
                //alert('password change');
            })
            .catch(error => { 
               setState({ showError: true, password: ""});
            });
        
      }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, sidePanOpen && classes.appBarShift)}> 
                <Toolbar className={classes.toolbar}>
                <IconButton edge="start" color="inherit"  aria-label="open drawer" onClick={sidePanOpen === true ? handleDrawerClose : handleDrawerOpen} className={clsx(classes.menuButton, sidePanOpen)}  >
                    <MenuIcon />
                </IconButton>
					<img src={logo} className={classes.logoTop}  alt="logo" /> 
					{/*<Typography component="h1" variant="h6" color="inherit" noWrap>
						Beeonline Communication Pvt. Ltd.
					</Typography>*/}       			       
					<IconButton color="inherit" onClick={handleClick}  className={classes.rightToolbar}> 
                        <AccountCircleIcon aria-describedby={id} variant="contained" />
                    </IconButton> 
                  {/*<Notification_data />*/}  
                </Toolbar>
                <Popover  id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorReference="anchorPosition" anchorPosition={{ top: 60, left: 1400 }} anchorOrigin={{ vertical:  'right',  horizontal: 'right' }} transformOrigin={{ vertical: 'right', horizontal: 'right' }} > 
                    <Typography style={{padding:'0.5rem  1rem', cursor:'pointer', fontWeight:'bold'}} color="inherit" noWrap onClick={handleDialogOpen}>Change Password</Typography>
                    <Typography style={{padding:'0rem  1rem 0.5rem', cursor:'pointer', fontWeight:'bold'}} color="inherit" noWrap onClick={logout}>Log Out</Typography>
                    {/*<ChangePassword />*/}
                </Popover>
            </AppBar>
           <Drawer variant="permanent" className={clsx(classes.drawer, { [classes.drawerOpen]: sidePanOpen, [classes.drawerClose]: !sidePanOpen, })} classes={{ paper: clsx(classes.drawerPaper, !sidePanOpen && classes.drawerPaperClose), }} open={sidePanOpen} >
                <Divider />
                <List style={{marginTop:"4rem"}}><Sidenav /></List>
              
            </Drawer>
            <Dialog open={dialogOpn} onClose={handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth={true} maxWidth={'xs'}>
                <DialogTitle id="alert-dialog-title">{"Change Password"}</DialogTitle>  
                    <DialogContent component="main" maxWidth="xs"> 
                        <form className=""  id="change-password"  onSubmit={submitHandler}>

                          <TextField variant="outlined" margin="normal" fullWidth label="Enter Old Password"  name="old_password" id="old_password" type="password" value={old_passwordState.old_password} autoComplete="old_password" onChange={old_Password_changeHandler} />
                          {old_passwordState.old_password_error !== ""?old_passwordState.old_password_error:''}

                         <TextField variant="outlined" margin="normal" fullWidth label="Enter New Password"  name="password" id="password" type="password" value={passwordState.password} autoComplete="password" onChange={password_changeHandler} />
                         {passwordState.password_error !== ""?passwordState.password_error:''}
                          
                          <TextField variant="outlined" margin="normal" fullWidth label="Confirm Password"  name="confirm_password" id="confirm_password" type="password" value={confirm_passwordState.confirm_password} autoComplete="confirm_password" onChange={confirm_password_changeHandler} />
                          {confirm_passwordState.confirm_password_error !== ""?confirm_passwordState.confirm_password_error:''}

                          <Button  type="submit" fullWidth  variant="contained" color="primary"> Reset Password </Button>
                        </form>
                         {state.showSuccess && <Alert severity="success">Password Changed Successfully !</Alert>}
                         {state.showError && <Alert severity="error">Something Went wrong! Please Try Again.</Alert>}
                    </DialogContent> 
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary"> Close </Button> 
                    </DialogActions> 
             </Dialog>
        </div>
    )
}