import React, { Component } from "react";
import axios from "axios";
import Helmet from "react-helmet";
import { CssBaseline } from "@material-ui/core";
import Footer from "./include/Footer";
import Header from "./include/Header";

const drawerWidth = 280;
class Testfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            count: 0,
            stateData: []
        }
        this.userData = this.userData.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    }

    handleDrawerOpen(drawerState) {
        this.setState({
          drawerOpen: drawerState,
        });
      }

    componentDidMount(){
        this.userData();
    }

    userData = () => {
        axios.get("https://beelive.in/navneet/api/common/get_state")
          .then((response) => {
              const resultData = response.data;
              console.log(resultData);
              this.setState({stateData: resultData.result})
          })
          .catch((error) => {
            console.log(error); 
          }); 
      }; 

    render(){
        return (
            <React.Fragment>     
            <Helmet>
            <title>Registered Teacher</title>
            </Helmet>
            <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
            <CssBaseline />
                <table style={{border:"1px solid", marginLeft:"350px", marginTop:"100px"}}>
                    <tr>
                        <th>Id</th>
                        <th>DB Id</th>
                        <th>State Name</th>
                    </tr>
                    {
                        this.state.stateData.map((arrList, index) => {
                            return <tr style={{border:"1px solid"}} key={index}>
                                    <td>{index+1}</td>
                                    <td>{arrList.id}</td>
                                    <td>{arrList.name}</td>
                                </tr>
                        })
                    } 
                </table>
            </React.Fragment>
        )
    }
}
export default Testfile