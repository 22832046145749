import React, { Component } from 'react';
import { apiURL } from '../utils';  
import Footer from '../include/Footer';
import Header from '../include/Header';
import { makeStyles, withStyles } from '@material-ui/core/styles'; 
import { CssBaseline, Grid, Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,Table, TableBody, TableCell, TableHead, TableRow, Snackbar, Switch, InputAdornment} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'; 
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';  
import Helmet from 'react-helmet';
import Delete from "../delete/Delete";
import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';   
import Box from '@material-ui/core/Box';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import "../style/Style.css";  
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import ClearIcon from '@material-ui/icons/Clear';
import Meta from "../meta/Meta";
import XLSX from 'xlsx';
import ExportExcelButton from '../utils/ExportExcelButton';
//import GetAppIcon from '@material-ui/icons/GetApp';

const drawerWidth = 280;
// const useStyles = makeStyles((theme) => ({
//     buttonEdit
// }))

const useStyles = theme => ({
    buttonEdit: {
      textTransform:'capitalize',
      border:'1px solid #035eab',
      borderRadius:'0px',
      marginRight:'10px',
      padding:'2px 5px',
    },
    buttonAdd:{
      textTransform:'capitalize',
    },
    table:{
        minWidth: 650,
    },
   
   
  });

 
 class Team extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
           // drawerOpen: false,
            drawerOpen: true,
            showDialog: false, 
            teamData: [],
            page: 1,
            per:20,
            total_pages: null,
            loading: false, 
            showSnackbar: false,
            snackBarMessage: '',
            severityColor: '',
            imagePreview: null,
            file: null,
            imageEdit: false,
            mode: '',   
            noData: ''
        }
        this.handleDrawerOpen       = this.handleDrawerOpen.bind(this);
        this.addDialog              = this.addDialog.bind(this);
        this.dialogClose            = this.dialogClose.bind(this);
        this.changeHandler          = this.changeHandler.bind(this);
        this.onFormSubmit           = this.onFormSubmit.bind(this);
        this.onEditFormSubmit       = this.onEditFormSubmit.bind(this);
        this.editDialogOpen         = this.editDialogOpen.bind(this); 
        this.changeStatus           = this.changeStatus.bind(this)
    }

    handleDrawerOpen(drawerState){
        this.setState({
          drawerOpen: drawerState,
        })
    }

    changeHandler = (e, type) => { 
        this.setState({ 
            [e.target.name]: e.target.value,
        }); 
        //console.log(e.target.name +'===='+e.target.value);
    }

    handleImageChange = (e) => {
        this.setState({  
            imagePreview: URL.createObjectURL(e.target.files[0]),
            file:e.target.files[0],
            imageEdit: true
         })
    }

    
    removeImage = (e) => {
        this.setState({  
            imagePreview: "",
            file:"",
            imageEdit: false,
            image: ""
         })
    }

    componentDidMount() { 
        this._isMounted = true; 
        this.teamListData(); 
        this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);
        });
        
    }

    componentWillUnmount() {
        this._isMounted = false; 
    }
  
    teamListData = () => {
        this.setState({ loading: true });
        const { per, page, teamData } = this.state; 
        const headers = { 
            'Content-type': 'application/json'
        }
        const body = {
            "search": document.getElementById('search').value
        }
        axios.post(apiURL('team?per_page='+per+'&page='+page), body, {headers: headers})
        .then(response => {
            
        if(this._isMounted){
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                this.setState({  
                    teamData: (resultData.total < 20)?resultData.result:[...this.state.teamData, ...resultData.result],
                    scrolling: false,
                    total_pages: resultData.total_pages,
                });
                this.setState({ loading: false, scrolling: true });
            }
            else{
                this.setState({ loading: false, scrolling: false, noData: "No Team Members" }); 
            }
        }
        })
        .catch(error => {
            console.log(error)
        })
    }

    loadMore = () => { 
        if(this._isMounted){
            this.setState(
            prevState => ({
                page: prevState.page + 1,
                scrolling: true
            }),
            this.teamListData
            ); 
        }
      };

      handleScroll = () => {   
        if(this._isMounted){ 
            var lastLi = document.querySelector("Table"); 
            if(lastLi !== null){
                var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
                var pageOffset = window.pageYOffset + window.innerHeight;
                if (pageOffset > lastLiOffset) {
                    if(this.state.scrolling){
                        this.loadMore();
                    }
                } 
            }
        }
      };

    onFormSubmit = (e) => { 
        e.preventDefault();
        const headers = {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json',
        }
        const formData  = new FormData();
        formData.append('file', this.state.file);
        formData.append('name', this.state.name);
        formData.append('designation', this.state.designation); 
        formData.append('facebook', this.state.facebook); 
        formData.append('twitter', this.state.twitter);  
        formData.append('skype', this.state.skype); 
        formData.append('linkedin', this.state.linkedin);  
        
        axios.post(apiURL('team/add'), formData, {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            {
                this.dialogClose();
                this.componentDidMount();
                this.setState({ imageEdit: false, imagePreview: null, file: null  });  
                this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
             }
             else{
                 this.setState({ 
                     showSnackbar: true, 
                     snackBarMessage:  resultData.message,
                     severityColor: 'error',
                     snackBarTimer: 2000
                 });
            }
            
        })
        .catch(error => {
            console.log(error)
        }) 
    }

    onEditFormSubmit = (e) => {
         e.preventDefault(); 
         const headers = {
             'content-type': 'multipart/form-data',
             'Accept': 'application/json'
         }
        
         const editFormData  = new FormData();
         editFormData.append('id', this.state.id);
         editFormData.append('name', this.state.name);
         editFormData.append('designation', this.state.designation); 
         editFormData.append('facebook', this.state.facebook); 
         editFormData.append('twitter', this.state.twitter); 
         editFormData.append('skype', this.state.skype); 
         editFormData.append('linkedin', this.state.linkedin); 
         editFormData.append('exist_file', this.state.image);
         editFormData.append('image_change', this.state.imageEdit);
         if(this.state.file !== null){
             editFormData.append('file', this.state.file);
         }

         axios.post(apiURL('team/edit'),editFormData, {headers: headers})
         .then(response => {
             const resultData = response.data; 
             if(resultData.status === true || resultData.status === 1)
             {
                 this.setState({ imageEdit: false, imagePreview: null, file: null });
                 this.dialogClose();
                 this.componentDidMount();  
                 this.setState({ 
                    showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000
                });
             }
             else{
                 this.setState({ 
                     showSnackbar: true, 
                     snackBarMessage:  resultData.message,
                     severityColor: 'error',
                     snackBarTimer: 2000
                 });
            }
         })
         .catch(error => {
             console.log(error)
         })
    }

    changeStatus = (id, status) => { 
        const headers = { 
            'Content-type': 'application/json'
        }
        axios.get(apiURL('team/change_status/'+id+'/'+status), {headers: headers})
        .then(response => {
            const resultData = response.data; 
            if(resultData.status === true || resultData.status === 1)
            { 
                const updatedTeam = this.state.teamData.map(team=>{
                    if(team.fldi_id === id){
                        const _team = {...team};
                        _team.flg_status = status==="0"?"1":"0";
                        return _team;
                    }
                    return  team;
                });
                this.setState({ teamData: updatedTeam, showSnackbar: true, 
                    snackBarMessage:  resultData.message,
                    severityColor: resultData.status === true?'success':'error',
                    snackBarTimer: 3000});
            }
        })
        .catch(error => {
            console.log(error)
        })
      }

    addDialog = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'add', 
            name: '',
            designation: '',
            facebook: '',
            twitter:"",
            skype:"",
            linkedin:"",
            file: '',
            image:'',
        });
    }

    editDialogOpen = (e) => { 
        this.setState({ 
            showDialog: true,
            mode: 'edit',
            id: e.fldi_id,
            name: e.fldv_name,
            designation: e.fldv_designation,
            facebook: e.fldv_facebook_url,
            twitter: e.fldv_twitter_url,
            skype: e.fldv_skype_url,
            linkedin: e.fldv_linkedin_url,
            image: e.fldv_image_url,
        });  
    }

    dialogClose = () => { 
        this.setState({ showDialog: false });
    }

    snackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ showSnackbar: false }); 
    }

    searchHandler = (e) => {
        this.setState({page: 1, searchValue:e.target.value}, () => { 
            this.teamListData();  
          })
    }
   
    successDelete = (e, id, index) => {  
        if(e === true){
            const deleteTeam = this.state.teamData;
            deleteTeam.splice(index, 1);
            this.setState({
                teamData: deleteTeam, 
                showSnackbar: true, 
                snackBarMessage: "data deleted suceesfully", 
                severityColor: 'success',
                snackBarTimer: 3000
            }); 
        } 

    }

    exportFile(e) {
        let teams = [["Name", "Designation", "Facebook", "Twitter", "Skype", "Linkedin", "Image", "Status", "Delete"]]
        this.state.teamData.forEach((team) => {
          let teamArray = [team.fldv_name, team.fldv_designation, team.fldv_facebook_url, team.fldv_twitter_url, team.fldv_skype_url, team.fldv_linkedin_url, team.fldv_image_url, (team.flg_status === '1')?"Active":"Deactive", (team.flg_is_deleted === '1')?"Not Delete":"Deleted"]
          teams.push(teamArray)
        })
    
        const wb = XLSX.utils.book_new()
        const wsAll = XLSX.utils.aoa_to_sheet(teams)
        
        XLSX.utils.book_append_sheet(wb, wsAll, "Team")
            XLSX.writeFile(wb, "export-team.xlsx")
    }

    render(){
        const { classes } = this.props; 
        const loadingCSS = {
            height: "30px",
            margin: "10px"
          };
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" }; 
        return(
            <div>
                <Helmet>
                    <title>Team</title>
                </Helmet>
                <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)}  />
                <CssBaseline />   
                <div style={{ paddingTop: '5rem', paddingLeft: (this.state.drawerOpen?drawerWidth+15:72+15), transition: 'padding 0.5s ease'   }}>
                    <main>    
                        <Container maxWidth="lg" > 
                        <Box  bgcolor="background.paper" mt={2} boxShadow={3}>
                        <Alert 
                            action={
                                <div>
                                    <TextField className="textField" type="text" placeholder="...Search"  
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            style: {color: '#414141',backgroundColor:'rgba(255, 255, 255, 0.29)'}
                                        }} 
                                        size="small"
                                        id="search"
                                        variant="outlined"
                                        style={{fontColor:"#fff", marginRight:'1rem',}} 
                                        name="search"
                                        onChange={e => this.searchHandler(e)} 
                                    /> 
                                    <Button className={classes.buttonAdd} variant="contained" color="primary" startIcon={<AddCircleOutlineOutlinedIcon/>}   onClick={this.addDialog} > Add Team </Button>  
                                    {/*<Button style={{marginLeft:"5px"}} color="inherit" className={classes.buttonAdd} startIcon={<GetAppIcon/>} color="secondary" variant="contained" onClick={(e) => this.exportFile(e)}>Export to Excel</Button>*/}
                                    <ExportExcelButton clickProp={(e) => this.exportFile(e)}/>
                                </div>
                            } 
                            variant="outlined" severity="default" boxShadow={3}><h2>Team</h2>
                        </Alert>
                         </Box>
                            <Grid container spacing={3}  align="center" > 
                                <Grid item xs={12}> 
                                <Box  bgcolor="background.paper" boxShadow={3} px={2} py={2} mt={1}>
                                <TableContainer  mt={1} component={Paper} className="customTable">
                                    <Table size="small" className={classes.table} stickyHeader aria-label="sticky table">
                                        <TableHead>     
                                            <TableRow>
                                                <TableCell>Id</TableCell> 
                                                <TableCell> Name</TableCell> 
                                                <TableCell> Designation</TableCell> 
                                                <TableCell> Facebook</TableCell> 
                                                <TableCell> Twitter</TableCell> 
                                                <TableCell> Skype</TableCell> 
                                                <TableCell> Linkedin</TableCell> 
                                                <TableCell>Image</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody> 
                                            {this.state.teamData.map((arrList, index) => {    
                                             return <TableRow key={arrList.fldi_id}>  
                                                    <TableCell>{index+1}</TableCell> 
                                                    <TableCell>{arrList.fldv_name}</TableCell> 
                                                    <TableCell>{arrList.fldv_designation}</TableCell> 
                                                    <TableCell>{arrList.fldv_facebook_url}</TableCell> 
                                                    <TableCell>{arrList.fldv_twitter_url}</TableCell> 
                                                    <TableCell>{arrList.fldv_skype_url}</TableCell> 
                                                    <TableCell>{arrList.fldv_linkedin_url}</TableCell> 
                                                    <TableCell><img style={{width:'25px', marginLeft:'10px'}} src={arrList.fldv_image_url} alt={arrList.fldv_name} /></TableCell>
                                                    <TableCell> <Switch size="small" checked={arrList.flg_status==="1"} color="primary" onChange={() => this.changeStatus(arrList.fldi_id, arrList.flg_status)}/></TableCell>
                                                    <TableCell>
                                                        <Button className={classes.buttonEdit} variant="outlined" color="default" startIcon={<EditIcon style={{color:'#035eab', fontSize:'1rem',}} />}  onClick={() => this.editDialogOpen(arrList)} > Edit</Button>
                                                        <Delete deleteID={arrList.fldi_id} table={'team'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/> 

                                                        <Meta primaryId={arrList.fldi_id} useFor={"team"} table={'blog'} onSuccessDelete={(e) => this.successDelete(e, arrList.fldi_id, index)}/>

                                                    </TableCell>
                                                </TableRow>  
                                            })} 
                                        </TableBody>
                                    </Table> 
                              </TableContainer>  
                              </Box>   
                                    <div ref={loadingRef => (this.loadingRef = loadingRef)} style={loadingCSS} >
                                        <span style={loadingTextCSS}>Loading...</span>
                                        <span>{(this.state.scrolling)?this.state.noData:""}</span>
                                    </div>
                                    
                                </Grid>
                           </Grid> 
                           <Footer />
                        </Container>
                    </main>
                </div> 

                <Dialog open={this.state.showDialog} fullWidth={true} maxWidth={'sm'}>
                    <form  id="courses" ref={c => { this.form = c }} onSubmit={this.state.mode === 'edit'?(e) => this.onEditFormSubmit(e):(e) => this.onFormSubmit(e) }  >
                        <DialogTitle id="form-dialog-title"><h3 style={{textAlign:'center'}}>{(this.state.mode === 'add')?'Add':'Edit'} Team </h3></DialogTitle>
                        <DialogContent style={{overflowY: 'hidden'}}>  
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Name" variant="outlined" name="name"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.name}  />
                                </Grid> 
                                
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Designation" variant="outlined" name="designation"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.designation}  />
                                </Grid> 
                                
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Facebook" variant="outlined" name="facebook"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.facebook}  />
                                </Grid> 
                                
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Twitter" variant="outlined" name="twitter"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.twitter}  />
                                </Grid> 
                                
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Skype" variant="outlined" name="skype"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.skype}  />
                                </Grid> 
                                
                                <Grid item xs={12} sm={12}>   
                                    <TextField fullWidth type="text" label="Linkedin" variant="outlined" name="linkedin"  size="small" onChange={ e => this.changeHandler(e)} value={this.state.linkedin}  />
                                </Grid> 

                                
                                <Grid item xs={12} sm={12}>
                                    {(this.state.imagePreview || this.state.image)?<ClearIcon  onClick={(e) => this.removeImage(e)} />: ""}
                                    {this.state.imageEdit === true?
                                        <img style={{width:'40px', display:'flex', marginBottom:'1rem', marginLeft:'12%'}} src={this.state.imagePreview}/>:
                                        <img style={{width:'40px', display:'flex', marginBottom:'1rem', marginLeft:'12%'}} src={this.state.image}/>
                                    }
                                    <Button variant="contained" startIcon={<ImageSearchIcon/>} color="primary" component="label">Profile Photo
                                        <input type="file" style={{ display: "none" }} onChange={this.handleImageChange} />
                                    </Button> 
                                   
                                </Grid>  
                             </Grid>
                        </DialogContent>
                        <DialogActions className="buttonCancel">
                            <Button variant="contained" onClick={() => this.dialogClose()} color="secondary"> Cancel </Button>
                            <Button type="submit" variant="contained" color="primary"> Submit </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                    <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                    { this.state.snackBarMessage }
                    </Alert>
                </Snackbar>
            </div>
        );
    }
 }
  
 export default (withStyles)(useStyles)(Team);