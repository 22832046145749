import { Container, Paper } from "@material-ui/core";
import React, { Fragment, useState, useEffect } from "react";
import {
  ArrowDropDown,
  ArrowDropUp,
  CallReceived,
  CallMade,
} from "@material-ui/icons";
import "./Dashboard.css";
import axios from "axios";
import { apiURL } from "../utils";
import Graph from "../images/Graph.png";
const cities = [
  "Achalpur",
  "Ahmednagar",
  "Akola",
  "Amravati",
  "Ambarnath",
  "Aurangabad",
  "Barshi",
  "Beed",
  "Bhivandi-Nizampur",
  "Bhusawal",
  "Chandrapur",
  "Dhule",
  "Gondia",
  "Hinganghat",
  "Ichalkaranji",
  "Jalgaon",
  "Jalna",
  "Kalyan-Dombivli",
  "Kolhapur",
  "Latur",
  "Malegaon",
  "Mira-Bhayandar",
  "Mumbai City",
  "Mumbai Suburban",
  "Nagpur",
  "Nanded Waghala",
  "Nandurbar",
  "Nashik",
  "Navi Mumbai",
  "Osmanabad",
  "Palghar",
  "Panvel",
  "Parbhani",
  "Pimpri-Chinchwad",
  "PMC, Pune",
  "Ratnagiri",
  "Sangli-Miraj-Kupwad",
  "Satara",
  "Sindhudurg",
  "Solapur",
  "Thane",
  "Ulhasnagar",
  "Udgir",
  "Vasai-Virar City MC",
  "Wardha",
  "Yavatmal",
];

const WeeklyStats = () => {
  const [language, setLanguage] = useState("Mumbai City");
  const [statsData, setStatsData] = useState({
    total: { current: "", change: "" },
    new: { current: "", change: "" },
    migrated: { current: "", change: "" },
    pending: { current: "", change: "" },
    cardDownloads: { current: "", change: "" },
  });

  useEffect(() => {
    fetchStats(language);
  }, [language]);

  const handleChange = (e) => {
    const lang = e.target.value;
    setLanguage(lang);
    fetchStats(lang);
  };

  const fetchStats = (language) => {
    const headers = {
      "Content-type": "application/json",
    };

    axios
      .get(apiURL("Count_teacher/weekly_teacher_data?city=" + language), {
        headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setStatsData({
            new: {
              current: resultData.result.new_user.current,
              change: resultData.result.new_user.change,
            },
            migrated: {
              current: resultData.result.migrant_user.current,
              change: resultData.result.migrant_user.change,
            },

            cardDownloads: {
              current: resultData.result.tr_for_teacher.current,
              change: resultData.result.tr_for_teacher.change,
            },
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderChangeIndicator = (change) => {
    if (change === 0 || change === "") return null;

    // Remove the percentage sign and convert to a number
    const numericChange = parseFloat(change.replace("%", ""));

    // Check if the change is positive
    const isPositive = numericChange > 0;

    const colorClass = isPositive ? "positive" : "negative";
    const ArrowIcon = isPositive ? CallMade : CallReceived;

    return (
      <span className={`change-indicator ${colorClass}`}>
        <ArrowIcon className="arrow-icon" />
        {isPositive ? `+${numericChange}%` : `${numericChange}%`} this week
      </span>
    );
  };

  return (
    <Fragment>
      <Container maxWidth="lg" className="container">
        <div className="header">
          <h2>Weekly Statistics</h2>
          <div className="filter">
            <div className="cityFilter">
              <div className="formControl">
                <select
                  id="language-select"
                  value={language}
                  onChange={handleChange}
                  className="select"
                  onFocus={(e) => e.target.classList.add("focused")}
                  onBlur={(e) => e.target.classList.remove("focused")}
                >
                  {cities.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
                <ArrowDropDown className="icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="cards">
          <Paper className="paper">
            <h3>Visitors</h3>
          </Paper>
          <Paper className="paper">
            <h3>New Users</h3>
            <p className="otherCount">{statsData.new.current}</p>
            {renderChangeIndicator(statsData.new.change)}
          </Paper>
          <Paper className="paper">
            <h3>Migrated Users</h3>
            <p className="otherCount">{statsData.migrated.current}</p>
            {renderChangeIndicator(statsData.migrated.change)}
          </Paper>
          <Paper className="paper">
            <h3>Pending Users for Migration</h3>
          </Paper>
          <Paper className="paper">
            <h3>Card Downloads</h3>
            <p>{statsData.cardDownloads.current}</p>
            <img src={Graph} alt="graph" />
            {renderChangeIndicator(statsData.cardDownloads.change)}
          </Paper>
        </div>
      </Container>
    </Fragment>
  );
};

export default WeeklyStats;
