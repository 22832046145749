import React, {Component } from 'react';  
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography'; 
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { login, apiURL } from '../utils'; 
import Helmet from 'react-helmet'; 
import logo from '../login-logo.png';
import { isLogin } from '../utils';

//import GoogleLogin from 'react-google-login';
//import FacebookLogin from 'react-facebook-login';
 
import Alert from '@material-ui/lab/Alert';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {/*<Link color="inherit" href="https://beeonline.co.in/">*/}
        NavDisha Enlightening Teachers
      {/*</Link>*/} {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignIn extends Component {
constructor(props){
  super(props);
  this.state={
    email:'',
    password:'', 
    showSuccessAlert: false,
    showFailAlert: false, 
    errorText: '', 
  }
 
}
 

componentDidMount() {
    if(isLogin())
    {
      this.props.history.push("/dashboard")
    }
}
changeHandler = (e) => {
  this.setState({ [e.target.name]: e.target.value })
}

submitHandler = e => {
  e.preventDefault()
  this.setState({error: false});

  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state)
  };
  fetch(apiURL('login'), requestOptions)
      .then(async response => {
          const data = await response.json();
          // check for error response
          if (!data.status) { 
              //return this.setState({error: true});
              return this.setState({
                showFailAlert: true,
                errorText: data.message
              })
          } 

          //localStorage.setItem('userData', JSON.stringify(data.result));
          localStorage.setItem('userData', JSON.stringify(data.result));
          login();
          this.props.history.push('/dashboard');
           
      })
      .catch(error => {
        this.setState({
          showFailAlert: true,
          password:""
        })
        console.error('There was an error!', error);
      });
  
}

snackBarClose = (event, reason) => {
  if (reason === 'clickaway') {
      return;
    }
    this.setState({ showFailAlert: false });
}

emailCheck = (e) => {
  console.log(this.state.email);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({"email": this.state.email})
};

fetch(apiURL('login/check_email'), requestOptions)
    .then(async response => {
        const data = await response.json(); 
        console.log(data);
        if(data.status === true)
        {
          if(data.result.fldv_source !== "web" || data.result.fldv_source === "")
          {
            return this.setState({
              showFailAlert: true, 
              errorText: "you have already register with "+ data.result.fldv_source + " Please login with "+ data.result.fldv_source +" or click on reset password",
            });
           
          }
        }
    })
    .catch(error => { 
      console.error('There was an error!', error);
    });
 
}

/*responseGoogle = (response) => {  
    const body = {
      "name":response.profileObj.name, 
      "email": response.profileObj.email,
      "source": "gmail"
    }
 
  return this.registerWithSocial(body); 
    
}

responseFacebook = (response) => {
  console.log('facebook response', response);
  const body = {
    "name":response.name, 
    "email": response.email,
    "source": "facebook"
  }
  return this.registerWithSocial(body); 
}

registerWithSocial = (body) =>{ 
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  fetch(apiURL('register'), requestOptions)
      .then(async response => {
          const data = await response.json();
          sessionStorage.setItem('userData', JSON.stringify(data.result));
          login();
          this.props.history.push('/dashboard'); 
      })
      .catch(error => { 
        console.error('There was an error!', error);
    });
}*/


render() {
  const { classes } = this.props;
    return (
      <div>
        <Helmet>
          <title>Sign - In / Login</title>
        </Helmet>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <img src={logo} alt="logo" />
            {/*<Avatar className={classes.avatar}> <LockOutlinedIcon /> </Avatar>
            <Typography component="h1" variant="h5"> Sign in  </Typography>*/}
            {this.state.showSuccessAlert && <Alert severity="success">Password Changed Successfully !</Alert>}
            {/*{this.state.showFailAlert && <Alert severity="error">{ this.state.errorText}</Alert>}*/}
            <form className={classes.form} noValidate onSubmit={this.submitHandler}>
              <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address"  name="email" autoComplete="email" autoFocus   onChange={e => this.changeHandler(e, 'email')} />
              <TextField variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password"  onChange={e => this.changeHandler(e, 'password')} />
              {/*<FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />*/}
              <Button  type="submit" fullWidth  variant="contained" color="primary" className={classes.submit}> Sign In </Button>
              <Grid container>
                {/*<Grid item xs>
                  <Link href="#" variant="body2"> Forgot password? </Link>
                </Grid>*/}
                {/*<Grid item>
                  <Link href="#" variant="body2"> {"Don't have an account? Sign Up"} </Link>
                </Grid>*/}
              </Grid>
            
            </form>
            {/*<GoogleLogin
              clientId="621490980909-pnnsqoultsfqe26auv5784f2k7n9n5pq.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
              buttonText="LOGIN WITH GOOGLE"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
            />

            <FacebookLogin
              appId="622648051623600" //APP ID NOT CREATED YET
              fields="name,email,picture"
              callback={this.responseFacebook}
            />*/}
          </div>
          <Box mt={8}> <Copyright /> </Box>
        </Container>
        <Snackbar open={this.state.showFailAlert} autoHideDuration={6000} onClose={this.snackBarClose}>
            <Alert onClose={this.snackBarClose} severity="error" elevation={6} variant="filled">
            { this.state.errorText}
            </Alert>
        </Snackbar>
      </div>
    );
  }
} 
export default  withStyles(useStyles)(SignIn);