import React, { Component } from 'react'; 
import { Editor } from '@tinymce/tinymce-react';   
import "./editor.css";
import Helmet from 'react-helmet';


/*import 'tinymce'; 
import 'tinymce/themes/silver';
import 'tinymce/plugins/table';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/powr';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/contextmenu';*/
 

 class TinyMceEditor extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = { 
            editorVlaue: '',
        }
        
    }
   
    handleEditorChange = (content, editor) => { 
        this.setState({
            editorVlaue: content
        });
      }
 
    render(){  
        return(
            <div>
                <Helmet>
                    <title>Editor</title>
                </Helmet> 
                
                {/*<textarea id="textarea">{this.state.editorVlaue}</textarea>*/}
                <Editor
                        initialValue={this.state.editorVlaue}
                        init={{
                        height: 188,
                        auto_focus: true,
                        cloudChannel:'5-dev',
                        id:'eac5d4e4_1598881784',
                        menubar: true,
                        type: 'panel',
                        disableAutoFocus:true,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help '
                        }}
                        onEditorChange={(e) => this.handleEditorChange(e)} 
                        
                    />
                                
            </div>
        );
    }
 }
  
 export default TinyMceEditor;