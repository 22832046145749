import React from "react";
import SanitizedHTML from "react-sanitized-html";

import { withFormik } from "formik";
import Yup from "yup";
const TOKEN_KEY = "jwt";
const date = new Date();

export const login = () => {
  localStorage.setItem("isLoggedin", true);
  // sessionStorage.setItem('isLoggedin', true);
};

export const logout = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("isLoggedin");
};

export const isLogin = () => {
  if (localStorage.getItem("isLoggedin")) {
    return true;
  }
};

export const apiURL = (method) => {
  // return "https://beelive.in/navneet/api/"+method;
  // return "https://navdisha.co.in/navneet/api/"+method;
  return "https://navdishaadminapi.navneet.com/api/" + method;
  // return "https://navdishastaging.navneet.com/navneet/api/" + method;
};

export const getCurrentDate = () => {
  return date.getDate();
};

export const getCurrentMonth = () => {
  let currentMonth = date.getMonth() + 1;
  return currentMonth < 10 ? 0 + "" + currentMonth : currentMonth;
};
export const getCurrentYear = () => {
  return date.getFullYear();
};

export const getCurrentTime = () => {
  return date.toLocaleString();
};

export const getAMPM = () => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const formatDateTime = () => {
  return (
    getCurrentDate() +
    "-" +
    getCurrentMonth() +
    "-" +
    getCurrentYear() +
    ":" +
    getCurrentTime() +
    ":" +
    getAMPM()
  );
};

export const change_date_format = (str) => {
  let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};

export const get_changed_time = (str, getampm) => {
  let date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  let hours = ("0" + date.getHours()).slice(-2);
  //let minutes = ("0" + date.getMinutes()).slice(-2);
  let minutes = ("" + date.getMinutes()).slice(-2);
  let seconds = ("0" + date.getSeconds()).slice(-2);
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  if (getampm === true) {
    // return [hours, minutes, seconds ].join(":")+' '+ampm;
    return [hours, minutes].join(":") + " " + ampm; // if require date time with ampm pass second parameter true
  } else {
    // return [hours, minutes, seconds ].join(":");
    return [hours, minutes].join(":");
  }
};

export const sanitizeHtml = (str) => {
  return <SanitizedHTML html={str} />;
};
