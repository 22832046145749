import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography'; 

class Footer extends Component{
    render(){
        return(
            <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {/*<Link color="inherit" target="_blank" href="https://beeonline.co.in/">*/}
                NavDisha Enlightening Teachers
            {/*</Link>*/}{' '}
            {new Date().getFullYear()}
            {'.'}
            </Typography>
        )
    }
}

export default Footer;