import { Container, Grid, Paper, Select, MenuItem } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { ArrowDropDown } from "@material-ui/icons";
import axios from "axios";
import { apiURL } from "../utils";
import "./Dashboard.css";
import Graph from "../images/Graph.png";
const cities = [
  "Achalpur",
  "Ahmednagar",
  "Akola",
  "Amravati",
  "Ambarnath",
  "Aurangabad",
  "Barshi",
  "Beed",
  "Bhivandi-Nizampur",
  "Bhusawal",
  "Chandrapur",
  "Dhule",
  "Gondia",
  "Hinganghat",
  "Ichalkaranji",
  "Jalgaon",
  "Jalna",
  "Kalyan-Dombivli",
  "Kolhapur",
  "Latur",
  "Malegaon",
  "Mira-Bhayandar",
  "Mumbai City",
  "Mumbai Suburban",
  "Nagpur",
  "Nanded Waghala",
  "Nandurbar",
  "Nashik",
  "Navi Mumbai",
  "Osmanabad",
  "Palghar",
  "Panvel",
  "Parbhani",
  "Pimpri-Chinchwad",
  "PMC, Pune",
  "Ratnagiri",
  "Sangli-Miraj-Kupwad",
  "Satara",
  "Sindhudurg",
  "Solapur",
  "Thane",
  "Ulhasnagar",
  "Udgir",
  "Vasai-Virar City MC",
  "Wardha",
  "Yavatmal",
];
const Statistics = ({ classes }) => {
  const [language, setLanguage] = useState("Mumbai City");
  const [statsData, setStatsData] = useState({
    total: "",
    new: "",
    migrated: "",
    pending: "",
    cardDownloads: "",
  });
  useEffect(() => {
    fetchStats("Mumbai City");
  }, []);
  const handleChange = (e) => {
    const lang = e.target.value;
    setLanguage(lang);
    fetchStats(lang);
  };
  const fetchStats = (language) => {
    const headers = {
      "Content-type": "application/json",
    };

    axios
      .get(
        apiURL("Count_teacher/count_teacher?city=" + language),

        { headers: headers }
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          console.log(resultData.result);
          setStatsData({
            total: resultData.result.total_user,
            new: resultData.result.new_user,
            migrated: resultData.result.migrant_user,
            pending: resultData.result.pending_user,
            cardDownloads: resultData.result.tr_for_teacher_percentage,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Fragment>
      <Container maxWidth="lg" className="container">
        <div className="header">
          <h2>Overall Statistics</h2>
          <div className="filter">
            <div className="cityFilter">
              <div className="formControl">
                <select
                  id="language-select"
                  value={language}
                  onChange={handleChange}
                  className="select"
                  onFocus={(e) => e.target.classList.add("focused")}
                  onBlur={(e) => e.target.classList.remove("focused")}
                >
                  {cities.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
                <ArrowDropDown className="icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="cards">
          <Paper className="paper">
            <h3>Total Users</h3>
            <p>{statsData.total}</p>
          </Paper>
          <Paper className="paper">
            <h3>New Users</h3>
            <p>{statsData.new}</p>
          </Paper>
          <Paper className="paper">
            <h3>Migrated User</h3>
            <p>{statsData.migrated}</p>
          </Paper>
          <Paper className="paper">
            <h3>Pending User for Migration</h3>
            <p>{statsData.pending}</p>
          </Paper>
          <Paper className="paper">
            <h3>Card Downloads</h3>
            <p>{statsData.cardDownloads}</p>
            <img src={Graph} alt="graph" />
          </Paper>
        </div>
      </Container>
    </Fragment>
  );
};

export default Statistics;
