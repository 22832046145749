import React, { Component } from "react";
import { Grid, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../utils";
import "../dashboard/Usermenu.css";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import registereduser from "../images/registereduser.png";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#ad180d"];
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2, 0, 2, 0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
  },
});

class Usermenu extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      totalUser: "",
      migratedUser: "",
      newUser: "",
      pendingUser: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Grid container className="MenuBox" spacing={3}>
          <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/user-count">
                {/* <img src="images/teachers.png" alt=""/> */}
                <img src={registereduser} width={99} height={99} />
                <h3>User Information</h3>
              </Link>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/training-menu">
                <img
                  src={process.env.PUBLIC_URL + "/images/training-schedule.png"}
                />
                <h3>Training</h3>
              </Link>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/announcement-news">
                <img src={process.env.PUBLIC_URL + "/images/news.png"} />
                <h3>Govt Circulars/GR</h3>
              </Link>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/article">
                <img src={process.env.PUBLIC_URL + "/images/blog.png"} />
                <h3>Articles</h3>
              </Link>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/faq">
                <img src={process.env.PUBLIC_URL + "/images/faq.png"} />
                <h3>FAQ's</h3>
              </Link>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/testimonials">
                <img
                  src={process.env.PUBLIC_URL + "/images/testimonials.png"}
                />
                <h3>Testimonials</h3>
              </Link>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/contact-us-enquiry">
                <img src={process.env.PUBLIC_URL + "/images/enquiry.png"} />
                <h3>Contact Us </h3>
              </Link>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/team">
                <img src={process.env.PUBLIC_URL + "/images/about-us.png"} />
                <h3>About Us</h3>
              </Link>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/tr-for-teacher">
                {/* <img src="images/teachers.png" alt=""/> */}
                <img src={process.env.PUBLIC_URL + "/images/teachers.png"} />
                <h3>Tr for Teachers</h3>
              </Link>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/menu">
                <img src={process.env.PUBLIC_URL + "/images/menu.png"} />
                <h3>Menu </h3>
              </Link>
            </Paper>
          </Grid>

          {/* <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/video">
                <img src={process.env.PUBLIC_URL + "/images/resources.png"} />
                <h3>Glimpses</h3>
              </Link>
            </Paper>
          </Grid> */}

          {/* <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/forum">
                <img
                  src={process.env.PUBLIC_URL + "/images/discussion-board.png"}
                />
                <h3>Discussion Board </h3>
              </Link>
            </Paper>
          </Grid> */}
          {/* <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/webinar">
                <img src={process.env.PUBLIC_URL + "/images/webinar.png"} />
                <h3>Webinar</h3>
              </Link>
            </Paper>
          </Grid> */}
          {/* <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/subscriber">
                <img src={process.env.PUBLIC_URL + "/images/subscribers.png"} />
                <h3>Subscribers </h3>
              </Link>
            </Paper>
          </Grid> */}
          {/* <Grid item md={3} sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Link to="/school-college">
                <img src={process.env.PUBLIC_URL + "/images/news.png"} />
                <h3>School/College</h3>
              </Link>
            </Paper>
          </Grid> */}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Usermenu);
