import React, { Component } from "react";
//import logo from './logo.svg';
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
//import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Dashboard from "./dashboard/Dashboard";
import Signin from "./sign-in/Signin";
import Article from "./resources/Article";
import Article_category from "./resources/Article_category";
import Video from "./resources/Video";
import Video_category from "./resources/Video_category";
import News from "./news/News";
import Training_schedule from "./training_schedule/Training_schedule";
import Speaker from "./training_schedule/Speaker";
import Training_schedule_feedback from "./training_schedule/Training_schedule_feedback";
import Join_training_user from "./training_schedule/Join_training_user";
import Subscribe from "./subscribe/Subscribe";
import Get_in_touch from "./enquiry/Get_in_touch";
import Contact_us_enquiry from "./enquiry/Contact_us_enquiry";
import Help_us_to_improve from "./enquiry/Help_us_to_improve";
import Category from "./discusion-board/Category";
import Forum from "./discusion-board/Forum";
import Forum_comments from "./discusion-board/Forum_comments";
import Blog from "./blog/Blog";
import Blog_Comments from "./blog/Blog_Comments";
import Faq from "./faq/Faq";
import Webinar from "./webinar/Webinar";
import Join_webinar_user from "./webinar/Join_webinar_user";
import Webinar_feedback from "./webinar/Webinar_feedback";
import Team from "./about_us/Team";
import Other_activities from "./about_us/Other_activities";
import Teacher from "./teacher/Teacher";
import Testimonials from "./testimonials/Testimonials";
import TinyMceEditor from "./editor/TinyMceEditor";
//import NetworkDetector from './utils/NetworkDetector';
import Menu from "./menu/Menu";
import Testfile from "./Testfile";

import Notification_data from "./include/Notification_data";
import TrainingMenu from "./training_schedule/TrainingMenu";
import Whatsapp from "./whatsapp/Whatsapp";
import SchoolCollege from "./school_college/SchoolCollege";
import UserCount from "./user_distribution/UserCount";
import UpcomingWebinar from "./webinar/UpcomingWebinar";
import RegisteredUsers from "./teacher/RegisteredUsers";
import UserList from "./teacher/UserList";
class App extends Component {
  /*constructor(props){
    super(props)
  }*/
  render() {
    return (
      <div className="App">
        {/*<NetworkDetector />*/}
        <Router basename={process.env.PUBLIC_URL}>
          <div>
            <Switch>
              <PublicRoute
                restricted={false}
                component={Signin}
                path="/sign-in"
              />
              <PrivateRoute component={Dashboard} path="/dashboard" />
              <PrivateRoute component={Article} path="/article" />
              <PrivateRoute
                component={Article_category}
                path="/article-category"
              />
              <PrivateRoute component={Video} path="/video" exact />
              <PrivateRoute
                component={Video_category}
                path="/video-category"
                exact
              />
              <PrivateRoute component={News} path="/announcement-news" exact />
              <PrivateRoute
                component={Training_schedule}
                path="/training-schedule"
                exact
              />{" "}
              <PrivateRoute
                component={TrainingMenu}
                path="/training-menu"
                exact
              />
              <PrivateRoute
                component={Training_schedule_feedback}
                path="/training-feedback/:trainingId/:trainingTitle"
                exact
              />
              <PrivateRoute
                component={Join_training_user}
                path="/join-training-user/:trainingId"
                exact
              />
              <PrivateRoute component={Speaker} path="/speaker" exact />
              <PrivateRoute component={Subscribe} path="/subscriber" exact />
              <PrivateRoute
                component={Get_in_touch}
                path="/get-in-touch"
                exact
              />
              <PrivateRoute
                component={Contact_us_enquiry}
                path="/contact-us-enquiry"
                exact
              />
              <PrivateRoute
                component={Help_us_to_improve}
                path="/help-us-to-improve"
                exact
              />
              <PrivateRoute component={Category} path="/category" exact />
              <PrivateRoute component={Forum} path="/forum" exact />
              <PrivateRoute
                component={Forum_comments}
                path="/forum/:forumId"
                exact
              />
              <PrivateRoute component={Blog} path="/blog" exact />
              <PrivateRoute
                component={Blog_Comments}
                path="/blog-comments/:blogId/:blogTitle"
                exact
              />
              <PrivateRoute component={Faq} path="/faq" exact />
              <PrivateRoute component={Webinar} path="/webinar" exact />
              <PrivateRoute
                component={UpcomingWebinar}
                path="/upcoming-webinar"
                exact
              />
              <PrivateRoute
                component={RegisteredUsers}
                path="/registered_user"
                exact
              />
              <PrivateRoute component={UserList} path="/tr-for-teacher" exact />
              <PrivateRoute
                component={Join_webinar_user}
                path="/join-webinar-user/:webinarId"
                exact
              />
              <PrivateRoute
                component={Webinar_feedback}
                path="/webinar-feedback/:webinarId/:webinarTitle"
                exact
              />
              <PrivateRoute component={Team} path="/team" exact />
              <PrivateRoute
                component={Other_activities}
                path="/other-activities"
                exact
              />
              <PrivateRoute component={Teacher} path="/teacher" exact />
              <PrivateRoute
                component={Testimonials}
                path="/testimonials"
                exact
              />
              <PrivateRoute component={Menu} path="/menu" exact />
              <PrivateRoute component={TinyMceEditor} path="/editor" exact />
              <PublicRoute component={Signin} path="/" exact />
              <PrivateRoute
                component={Notification_data}
                path="/notification"
                exact
              />
              <PrivateRoute
                restricted={false}
                component={Whatsapp}
                path="/whatsapp"
              />
              <PrivateRoute component={SchoolCollege} path="/school-college" />
              <PrivateRoute component={UserCount} path="/user-count" />
              {/* <Route path="/sign-in" component={Signin} />
              <Route path="/dashboard"component={Dashboard}  />
              <Route path="/article"  component={Article} />
              <Route path="/article-category" component={Article_category}  />
              <Route path="/video"  component={Video} />
              <Route path="/video-category"  component={Video_category} />
              <Route path="/announcement-news" component={News} />
              <Route path="/training-schedule" component={Training_schedule} />
              <Route path="/training-feedback/:trainingId/:trainingTitle" component={Training_schedule_feedback} />
              <Route path="/join-training-user/:trainingId" component={Join_training_user} /> 
              <Route path="/speaker" component={Speaker} />
              <Route path="/subscriber" component={Subscribe} />
              <Route path="/get-in-touch" component={Get_in_touch} />
              <Route path="/contact-us-enquiry" component={Contact_us_enquiry} />
              <Route path="/help-us-to-improve" component={Help_us_to_improve} />
              <Route path="/category" component={Category} />
              <Route path="/forum" component={Forum} />
              <Route path="/forum/:forumId" component={Forum_comments} />
              <Route path="/blog" component={Blog} />
              <Route path="/faq" component={Faq} />
              <Route path="/webinar" component={Webinar} />
              <Route path="/join-webinar-user/:webinarId" component={Join_webinar_user} /> 
              <Route path="/webinar-feedback/:webinarId/:webinarTitle" component={Webinar_feedback} /> 
              <Route path="/team" component={Team} />
              <Route path="/other-activities" component={Other_activities} />
              <Route path="/teacher" component={Teacher} />
              <Route path="/testimonials" component={Testimonials} /> 
              <Route path="/menu"  component={Menu} />
              <Route path="/editor" component={TinyMceEditor} />
              <Route path="/usermenu" component={Usermenu} />
              <Route path="/test" component={Testfile} />
              <Route path="/*" component={Signin} /> */}
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
